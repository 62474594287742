<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>스피커음원관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1261px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 320px; margin-left: 15px; margin-right: 20px">
							<div class="form-group">
								<label style="width: 65px">{{ detailsFieldMap.siteNm }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.siteNm">
										<option value="">전체</option>
										<option v-for="(row, index) in options.siteOptions" :key="index" :value="row.siteNm">{{ row.siteNm }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-3" style="width: 320px; margin-right: 20px">
							<div class="form-group">
								<label style="width: 65px">{{ detailsFieldMap.objectId }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.objectId">
										<option value="">전체</option>
										<option v-for="(row, index) in options.objectOptions" :key="index" :value="row.value">{{ row.text }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-3" style="width: 323px; margin-right: 20px">
							<div class="form-group">
								<label style="width: 120px">{{ detailsFieldMap.targetId }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.targetId">
										<option value="">전체</option>
										<option v-for="(row, index) in options.targetOptions" :key="index" :value="row.value">{{ row.text }}</option>
									</select2>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3" style="width: 360px; margin-left: -10px; margin-right: 20px">
							<div class="form-group">
								<label style="width: 90px">{{ detailsFieldMap.soundsrcSaveLocation }}</label>
								<input
									type="text"
									class="form-control"
									id="startInput"
									style="width: 220px"
									:placeholder="detailsFieldMap.soundsrcSaveLocation"
									v-model="searchVM.soundsrcSaveLocation"
								/>
							</div>
						</div>
						<div class="col-md-3" style="width: 320px; margin-left: -20px; margin-right: 20px">
							<div class="form-group">
								<label style="width: 70px">{{ detailsFieldMap.soundsrcCont }}</label>
								<input
									type="text"
									style="width: 220px"
									class="form-control"
									:placeholder="detailsFieldMap.soundsrcCont"
									v-model="searchVM.soundsrcCont"
								/>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								스피커 음원 관리 정보
							</legend>
							<div class="col-md-6">
								<!-- 음원 ID, 수정모드만 표시 -->
								<div v-if="isEditMode">
									<jarvis-field
										:label="detailsFieldMap.soundsrcId"
										v-if="isEditMode"
										required="true"
										disabled="true"
										field="detailsItem.soundsrcId"
										v-model="detailsItem.soundsrcId"
										data-vv-name="detailsItem.soundsrcId"
									></jarvis-field>
								</div>
								<!-- 현장 ID -->
								<jarvis-field :label="detailsFieldMap.siteNm" field="detailsItem.siteNm" required="true">
									<select2
										v-model="detailsItem.siteId"
										data-vv-name="detailsItem.siteNm"
										v-validate="validationRule.detailsItem.siteId"
										:options="options.siteOptions"
										id="firstInputCreate"
									>
										<option value="">선택</option>
										<option value="0000000000">없음</option>
									</select2>
								</jarvis-field>
								<!-- 대상물 코드 -->
								<jarvis-field :label="detailsFieldMap.objectId" :required="true" field="detailsItem.objectId">
									<select2
										v-model="detailsItem.objectId"
										:options="options.objectOptions"
										data-vv-name="detailsItem.objectId"
										v-validate="validationRule.detailsItem.objectId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 조치대상 코드 -->
								<jarvis-field :label="detailsFieldMap.targetId" field="detailsItem.targetId">
									<select2
										v-model="detailsItem.targetId"
										:options="options.targetOptions"
										data-vv-name="detailsItem.targetId"
										v-validate="validationRule.detailsItem.targetId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 대상물인식구분 -->
								<jarvis-field :label="detailsFieldMap.objectRecognitionDvsnCd" field="detailsItem.objectRecognitionDvsnCd" required="true">
									<select2
										v-model="detailsItem.objectRecognitionDvsnCd"
										:options="options.objectRecognitionOptions"
										data-vv-name="detailsItem.objectRecognitionDvsnCd"
										v-validate="validationRule.detailsItem.objectRecognitionDvsnCd"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 음원 저장 위치 -->
								<jarvis-field
									:label="detailsFieldMap.soundsrcSaveLocation"
									required="true"
									field="detailsItem.soundsrcSaveLocation"
									v-model="detailsItem.soundsrcSaveLocation"
									data-vv-name="detailsItem.soundsrcSaveLocation"
									v-validate="validationRule.detailsItem.soundsrcSaveLocation"
								></jarvis-field>
								<!-- 음원 재생 시간 -->
								<jarvis-field
									:label="detailsFieldMap.soundsrcPlayTime"
									required="true"
									field="detailsItem.soundsrcPlayTime"
									v-model="detailsItem.soundsrcPlayTime"
									data-vv-name="detailsItem.soundsrcPlayTime"
									v-validate="validationRule.detailsItem.soundsrcPlayTime"
								></jarvis-field>
								<!-- 음원 재생 횟수 -->
								<jarvis-field
									:label="detailsFieldMap.soundsrcPlayCnt"
									required="true"
									field="detailsItem.soundsrcPlayCnt"
									v-model="detailsItem.soundsrcPlayCnt"
									data-vv-name="detailsItem.soundsrcPlayCnt"
									v-validate="validationRule.detailsItem.soundsrcPlayCnt"
								></jarvis-field>
								<!-- 음원 내용 -->
								<jarvis-field
									:label="detailsFieldMap.soundsrcCont"
									required="true"
									field="detailsItem.soundsrcCont"
									v-model="detailsItem.soundsrcCont"
									data-vv-name="detailsItem.soundsrcCont"
									v-validate="validationRule.detailsItem.soundsrcCont"
								></jarvis-field>
							</div>
							<div class="col-md-6">
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>
						</fieldset>
					</div>

					<div class="row"></div>
					<!-- 버튼 추가모드만 표시 -->
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<!-- 버튼 수정모드만 표시 -->
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const speakerSoundsrcApi = apiIndex.speakerSoundsrc;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		siteId: 'required',
		objectId: 'required',
		objectRecognitionDvsnCd: 'required',
		soundsrcSaveLocation: 'max:100|numOnly|required',
		soundsrcPlayTime: 'numDecimalOnly|required',
		soundsrcPlayCnt: 'numOnly|required',
		soundsrcCont: 'max:200|required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('speakerSoundsrcPageParam'));

		if (!this.pageParam) location.href = apiIndex.mainUrl;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		axiosExtention = this.$jarvisExtention.axiosExtention;

		//기본 현장 세팅
		this.searchVM.siteNm = this.pageParam.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.options = {
			siteOptions: this.pageParam.siteOptions,
			objectRecognitionOptions: this.pageParam.objectRecognitionOptions,
			objectOptions: this.pageParam.objectOptions,
			targetOptions: this.pageParam.targetOptions,
		};

		// jarvis-field에서 사용하기 위함
		this.options.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});

		this.options.objectOptions.forEach(e => {
			e.text = e.objectNm + '(' + e.objectId + ')';
			e.value = e.objectId;
		});

		this.options.targetOptions.forEach(e => {
			e.text = e.targetNm + '(' + e.targetId + ')';
			e.value = e.targetId;
		});

		this.options.objectRecognitionOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		// 그리드 컬럼의 objectRecognitionDvsnCd를 한글로 변경할 때 사용
		this.gridColumns.filter(item => item.field == 'objectRecognitionDvsnCd')[0].mapData = this.options.objectRecognitionOptions;
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});

		this.search();
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: speakerSoundsrcApi.inqSpeakerSoundsrc,
		},
		searchVM: {
			siteNm: null,
			objectId: '',
			targetId: '',
			soundsrcSaveLocation: null,
			soundsrcCont: '',
		},
		options: {},
		searchState: {}, //적용된 검색조건
		//그리드설정
		gridColumns: [
			{ field: 'soundsrcId', title: '음원ID' },
			{ field: 'siteNm', title: '현장명' },
			{ field: 'objectNm', title: '대상물명' },
			{ field: 'targetNm', title: '조치대상명' },
			{ field: 'objectRecognitionDvsnCd', title: '대상물인식구분', dataFormat: 'optionMap', align: 'center' },
			{ field: 'soundsrcSaveLocation', title: '음원 저장 위치', align: 'right' },
			{ field: 'soundsrcPlayTime', title: '음원 재생 시간(초)', align: 'right' },
			{ field: 'soundsrcPlayCnt', title: '음원 재생 횟수', align: 'right' },
			{ field: 'soundsrcCont', title: '음원 내용' },
			{
				field: 'regDtm',
				title: '등록일',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'updDtm',
				title: '수정일',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			soundsrcId: '음원ID',
			siteNm: '현장명',
			objectId: '대상물ID',
			targetId: '조치대상ID',
			objectRecognitionDvsnCd: '대상물인식구분',
			soundsrcSaveLocation: '음원 저장 위치',
			soundsrcPlayTime: '음원재생시간(초)',
			soundsrcPlayCnt: '음원 재생 횟수',
			soundsrcCont: '음원 내용',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '최종 수정일',
			updUser: '최종 수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: speakerSoundsrcApi.exlSpeakerSoundsrc,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName =
						'스피커음원관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(speakerSoundsrcApi.inqOneSpeakerSoundsrc, { soundsrcId: selectedRowItem.soundsrcId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				siteId: '',
				objectId: '',
				targetId: '',
				objectRecognitionDvsnCd: '',
				soundsrcSaveLocation: null,
				soundsrcPlayTime: null,
				soundsrcPlayCnt: 1,
				soundsrcCont: null,
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			this.$validator.validateAll().then(
				function (success) {
					if (success == false) {
						return;
					}
					this.$axios
						.post(speakerSoundsrcApi.insSpeakerSoundsrc, this.detailsItem)
						.then(
							function () {
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}.bind(this),
			);
		},
		//수정저장
		updateData() {
			this.$validator.validateAll().then(
				function (success) {
					if (success == false) {
						return;
					}
					if (confirm('수정한 데이터를 저장하시겠습니까?')) {
						const soundsrcId = this.detailsItem.soundsrcId;
						this.$axios
							.put(speakerSoundsrcApi.updSpeakerSoundsrc + '/' + soundsrcId, this.detailsItem)
							.then(
								function () {
									this.loadGrid();
								}.bind(this),
							)
							.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
					}
				}.bind(this),
			);
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			const soundsrcId = this.detailsItem.soundsrcId;
			this.$axios
				.delete(speakerSoundsrcApi.delSpeakerSoundsrc + '/' + soundsrcId)
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style>
[v-cloak] > * {
	display: none !important;
}
.select-wrapper {
	min-width: 220px !important;
	width: 220px !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 50%;
	margin-top: 5px;
	max-width: 130px;
}

.control-label {
	position: relative;
	min-height: 1px;
	padding-left: 0px !important;
	padding-right: 5px !important;
}
</style>
